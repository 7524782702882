<template>
    <div>
        <CRow>
            <CCol sm="4">
                <CCard>
                    <CCardHeader>
                        <CIcon name="cil-justify-center"/>
                        <strong> All Tickets </strong><small></small>
                    </CCardHeader>
                    <CCardBody>
                        <CListGroup>
                            <CListGroupItem
                                    @dblclick="getDetails(ticket)"
                                    v-for="ticket in tickets_list"
                                    href="#"

                                    class="flex-column align-items-start"
                            >
                                <div class="d-flex w-100 justify-content-between">
                                    <h5 class="mb-1">{{ticket.ticket_title}}</h5> <span> Reported by {{ticket.ticket_reporter}} </span>
                                    <small>{{ new Date(ticket.ticket_created).toDateString() }}</small>
                                </div>
                                <p v-html="ticket.ticket_description" class="mb-1"></p>

                            </CListGroupItem>
                        </CListGroup>
                    </CCardBody>
                </CCard>
            </CCol>
            <CCol sm="4">
                <CCard>
                    <CCardHeader>
                        <CIcon name="cil-justify-center"/>
                        <strong> Tickets assigned to you </strong><small></small>
                    </CCardHeader>
                    <CCardBody>
                        <CListGroup>
                            <CListGroupItem
                                    v-for="ticket in tickets_list"
                                    href="#"

                                    class="flex-column align-items-start"
                            >
                                <div class="d-flex w-100 justify-content-between">
                                    <h5 class="mb-1">{{ticket.ticket_title}}</h5> <span> Reported by {{ticket.ticket_reporter}} </span>
                                    <small>{{ new Date(ticket.ticket_created).toDateString() }}</small>
                                </div>
                                <p v-html="ticket.ticket_description" class="mb-1"></p>

                            </CListGroupItem>
                        </CListGroup>
                    </CCardBody>
                </CCard>
            </CCol>

            <CCol sm="4">
                <CCard>
                    <CCardHeader>
                        <CIcon name="cil-justify-center"/>
                        <strong> Statistics </strong><small></small>
                    </CCardHeader>
                    <CChartPie
                            :datasets="defaultDatasets"
                            :labels="['Resolved', 'Open']"
                    />
                    <br>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>

<script>import axios from 'axios';
import {CChartBar, CChartPie} from "@coreui/vue-chartjs";

export default {
    name: 'CreateTicket',
    components: { CChartPie },
    computed: {
        defaultDatasets () {
            return [
                {
                    backgroundColor: [
                        '#41B883',
                        '#E46651',
                    ],
                    data: [1, 2]
                }
            ]
        }
    },
    data() {
        return {

            tickets_list: [],


        }
    },
    methods: {
        getDetails(ticket){
            console.log(ticket);
        },
        getTickets() {

            axios.post(this.$baseURL + '/ticket/all', {})
                .then((res) => {
                    this.tickets_list = res.data;
                }).catch((error) => {
                console.log(error);
            })


        }
    },
    watch: {
        $route: {
            immediate: true,
            handler(route) {
                if (route.query && route.query.page) {
                    this.activePage = Number(route.query.page)
                }
            }
        }
    },
    mounted() {
        this.getTickets()
    }
}
</script>
